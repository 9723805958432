import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { ethers } from "ethers"

import {
  useAddress,
  ConnectWallet,
  useContract,
  useContractWrite,
  Web3Button
} from "@thirdweb-dev/react";

function App() {

  /* global BigInt */

  const navigate = useNavigate();
  const address = useAddress();
  const [mintvalue, setMintValue] = useState('');
  const [etherBalance, setEtherBalance] = useState('');


  useEffect(() => {
    if (address) {
      getStats(address);
    }
  }, [address]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); 
      return () => {
      clearTimeout(timer);
    };
  }, []);

  const contractVanilla = {
    address: "0xcAcb0a5bb1f52F00a14bDA0dc85dE81392B2892B",
    abi: [
      "function claimTokensWithAllowlist(bytes calldata _voucher, uint256 _id, uint256 _amount) public",
      "function buyTokensWithEth(uint256 _id, uint256 _amount) public payable",
      "function buyTokensWithERC20(uint256 _id, uint256 _amount) public payable",
      "function buyTokensWithCreditCard(address wallet, uint256 _id, uint256 _amount) public payable",
      "function allowlistMinted(address, uint256) public view returns (bool)",
      "function balanceOf(address account, uint256 id) external view returns (uint256)",
      "function getTokenDataFor(uint256[] calldata ids) external view returns (tuple(bool buyable, uint64 supply,uint64 minted, string uri, uint256 price, uint256 erc20Price)[] memory)"
    ]
};

async function getStats(address) {
  const RPC = "https://eth-mainnet.g.alchemy.com/v2/uY4eHA9TUYaqCjKOmFT-4_SrEWz4cGEv";
  const provider = new ethers.providers.JsonRpcProvider(RPC);
  const CONTRACT_ADDRESS = new ethers.Contract(contractVanilla.address, contractVanilla.abi, provider);
  
  // Getting Ether balance
  let etherBalance = await provider.getBalance(address);
  // Converting Wei (the unit getBalance() returns) to Ether
  etherBalance = ethers.utils.formatEther(etherBalance);
  etherBalance = parseFloat(etherBalance).toFixed(3);  
  
  var balance = await CONTRACT_ADDRESS.balanceOf(address,1);
  var get  = await CONTRACT_ADDRESS.getTokenDataFor([1]);
  var minted = 15000 - Number(BigInt(get[0].minted));

  document.getElementById("your-tokens").innerHTML = "Your Tokens: " + balance;
  // Add Ether balance to HTML
  document.getElementById("your-ether").innerHTML = "Your ETH: " + etherBalance + " ETH";
  document.getElementById("available").innerHTML = "Available: " + minted;

  setEtherBalance(etherBalance);

  if(minted <= 0){
    document.getElementById("show").style.display="none"
    document.getElementById("available").innerHTML = "Sold Out";
    document.getElementById("available").style.color="red"
  }
}

  const COST_ETH = 0.00365;
  const PAYMENTFEE_ETH = 0.0006;
  const _id = 1;

    const previousAddressRef = useRef();
    useEffect(() => {
      previousAddressRef.current = address;
    }, [address]);
    const previousAddress = previousAddressRef.current;

    useEffect(() => {
      if (previousAddress && !address) {
        navigate("/")
      }
    }, [address, previousAddress]);

    function set(x){
      if(x > 0){
      setMintValue(x);
      document.getElementById("show-fee").style.display=null;
      mintvalueRef.current = x;
    }
    else{
      setMintValue(0);
      setButtonClass("btn btn-primary btn-connect about-us disabled")
      document.getElementById("show-fee").style.display="none";
    }
    }


    const [buttonClass, setButtonClass] = useState("btn btn-primary btn-connect about-us disabled");

    function trackTx(event) {
      if(event){
        console.log("success")
        console.log(event)
      }
      else{
        console.log(event)

      }
    }

    const { contract } = useContract("0xcAcb0a5bb1f52F00a14bDA0dc85dE81392B2892B");
    const { mutateAsync: buyTokensWithEth, isLoading } = useContractWrite(contract, "buyTokensWithEth")

    const refresh =  () => {
      window.location.reload();
    }

    const mintvalueRef = useRef(mintvalue);

    useEffect(() => {
      mintvalueRef.current = mintvalue;

      var ether = COST_ETH*mintvalue +  PAYMENTFEE_ETH*mintvalue

      if(ether > etherBalance){
        alert("Insufficient funds for this amount");
        setMintValue(0);
        document.getElementById("number-input").value = "";
        document.getElementById("show-fee").style.display = "none";
      }
    }, [mintvalue]);

    function go() {
      document.getElementById("stats-minting").style.display = "none";
      document.getElementById("input-minting").style.display = "none";
      document.getElementById("claimed").style.display = null;
      document.getElementById("refresh").style.display = null;
      document.getElementById("show-fee").style.display = "none";
      document.getElementById("web3").style.display = "none";
    }
  
  
  return (
  <main className="page-wrapper">

<header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
      <div className="container px-3">
        <a href="https://atsnft.io/" className="navbar-brand pe-3">
          <img src="ape.png" width={47} alt="Logo" />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <a href="https://records.atsnft.io/" className="nav-link text-nowrap">Records</a>
              </li>
              <li className="nav-item">
              <a href="https://mint.atsnft.io/" className="nav-link text-nowrap" >Looking Glass</a>
              </li>
              <li className="nav-item">
              <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
              </li>
              <li className="nav-item">
              <a href="#" className="nav-link text-nowrap active" style={{color:"#e9ca7a"}}>War Bonds</a>
              </li>
              <li className="nav-item">
              <a href="https://war.atsnft.io/" className="nav-link text-nowrap" >War</a>
              </li>
              <li className="nav-item">
              <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
              </li>
              <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
             <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
             
            </ul>
          </div>
          <div className="offcanvas-header border-top">
          </div>      
        </div> 
        <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </header>

    <section id="module" className="mint-page-bonds" >
    

          <div  id="rota-public-mint" className="row justify-content-center text-center pb-4 mb-2 mint-module">
            <div className="col-xl-12 col-lg-9 col-md-10">
              <br></br>
            <div className="connect-section" style={{marginBottom:"15px"}}>
            <ConnectWallet
             btnTitle="Connect Wallet"
                theme="dark"
              />
            </div>
         <section id="stats-minting">
            <div className="mint-text-small"><b>Price: {COST_ETH} ETH</b></div><br></br>
            <div className="mint-text-small" id="your-tokens"></div><br></br>
            <div className="mint-text-small" id="your-ether"></div><br></br>
            <div className="mint-text-small" id="available"></div><br></br>
        </section>


    <div id="show">
        {loading ? (
      <div className="mint-text-small">Loading...</div>
    ) : (
      <section id="input-minting" className="input-section col-xl-10 col-md-5 py-1" >
        <div id="number-input-group" className="input-group mb-4">
          <input id="number-input" type="number" placeholder="# of war bonds" className="form-select  mint-text-small" onChange={e => set(e.target.value)}/>
        </div>
      </section>
    )}
    

    {loading ? (
  <div></div>
) : (
  <>
    
    <div id="claimed" style={{ display:"none", maxWidth: "500px", textAlign: "center", margin: "0 auto", marginBottom: "15px" }}>
                  Your token purchase for {mintvalue} warbonds has been initiated to the network. Accept the transaction to submit it to the network. Once completed, you can view
                  your {mintvalue} warbonds on{" "}
                  <a
                    href="https://opensea.io/assets/ethereum/0xcacb0a5bb1f52f00a14bda0dc85de81392b2892b/1"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    OpenSea
                  </a>{" "}
                  &amp; utilize your {mintvalue} warbonds in the war dApp on{" "}
                  <a
                    href="https://www.war.atsnft.io/"
                    target="_blank"
                    style={{textDecoration:"none"}}
                  >
                    ApeRecords
              </a>
       </div>

  <div id="web3">

        {mintvalue > 0 ? (
                    <Web3Button
                          contractAddress="0xcAcb0a5bb1f52F00a14bDA0dc85dE81392B2892B"
                          action={(contract) => {
                            var ether = COST_ETH*mintvalue +  PAYMENTFEE_ETH*mintvalue
                            var parsedEther = ethers.utils.parseEther(ether.toString());
                            contract.call("buyTokensWithEth", [_id, Number(mintvalue)],
                            {value: parsedEther}
                            )
                          }}
                          onSuccess={() => go()}
                          onSubmit={() => go()}
                        >
                          Buy {mintvalue} Tokens
                        </Web3Button>
                ) : null}

    </div>     

    <button id="refresh" style={{display:"none",marginLeft:"15px"}} className="btn btn-primary btn-connect about-us" onClick={refresh}>Go Home</button>

    <div id="show-fee" className="mint-text-extra-small" style={{display:"none"}}>+ $1 per mint fee</div>
  </>
)}

            </div> 

            </div>


            <div className="col-xl-8 col-lg-9 col-md-10">
            <a href="https://etherscan.io/address/0xcacb0a5bb1f52f00a14bda0dc85de81392b2892b" target="_blank" className="btn btn-icon btn-secondary btn-facebook rounded-circle mx-1 d-lg-inline-flex"><img style={{height:"20px"}} src="etherscan.png"></img></a>
            <a href="https://opensea.io/collection/warbonds" target="_blank" className="btn btn-icon btn-secondary btn-twitter rounded-circle mx-1 d-lg-inline-flex"><img style={{height:"25px"}} src="opensea.png"></img></a>
            </div>       
          </div>

</section>
</main>

  );
}

export default App;
