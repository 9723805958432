import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThirdwebProvider } from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThirdwebProvider 
    activeChain="ethereum"
    clientId="38c6c744ba8d70ac2d7eeb760f7c3a0a"
    sdkOptions={{
        gatewayUrls: ["https://ipfs.io/ipfs/"],
      }}
    >
    <App/>
</ThirdwebProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
