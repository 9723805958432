import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams  } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { ethers } from "ethers"
import {
  useAddress,
  ConnectWallet,
  useContract,
  useContractWrite,
} from "@thirdweb-dev/react";


function App() {

  /* global BigInt */

  const navigate = useNavigate();

  const address = useAddress();

  useEffect(() => {
    if (address) {
      navigate("/warbonds")
    }
  }, [address]);

    const previousAddressRef = useRef();
    useEffect(() => {
      previousAddressRef.current = address;
    }, [address]);
    const previousAddress = previousAddressRef.current;

    useEffect(() => {
      if (previousAddress && !address) {
        document.getElementById("eligible").style.color="white";
        document.getElementById("eligible").innerHTML = "CLAIM PERIOD COMPLETED"
      }
    }, [address, previousAddress]);

  return (
  <main className="page-wrapper">

<header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
      <div className="container px-3">
        <a href="https://atsnft.io/" className="navbar-brand pe-3">
          <img src="ape.png" width={47} alt="Logo" />
        </a>
        <div id="navbarNav" className="offcanvas offcanvas-end">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <a href="https://records.atsnft.io/" className="nav-link text-nowrap">Records</a>
              </li>
              <li className="nav-item">
              <a href="https://mint.atsnft.io/" className="nav-link text-nowrap" >Looking Glass</a>
              </li>
              <li className="nav-item">
              <a href="https://marketplace.atsnft.io/" target="_blank" className="nav-link text-nowrap">Marketplace</a>
              </li>
              <li className="nav-item">
              <a href="#" className="nav-link text-nowrap active" style={{color:"#e9ca7a"}}>War Bonds</a>
              </li>
              <li className="nav-item">
              <a href="https://war.atsnft.io/" className="nav-link text-nowrap" >War</a>
              </li>
              <li className="nav-item">
              <a href="https://records.atsnft.io/tickets" className="nav-link text-nowrap">Tickets</a>
              </li> 
              <a href="https://twitter.com/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-twitter d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-twitter"></i></a>
             <a href="https://discord.com/invite/ATSNFT" target="_blank" className="btn btn-icon btn-secondary btn-discord d-none rounded-circle mx-1 d-lg-inline-flex"><i className="bx bxl-discord"></i></a>
             
            </ul>
          </div>
          <div className="offcanvas-header border-top">
          </div>      
        </div> 
        <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    </header>

    <section id="module" className="mint-page-bonds" >
      <div  id="connect-wallet" className="row justify-content-center text-center pb-4 mb-2 mint-module">
            <div className="col-xl-12 col-lg-9 col-md-10 py-4">
          <br></br>
            <div className="mint-text-large"><b>War Bonds</b></div>
            {/* <a href="https://opensea.io/collection/warbonds" target="_blank"><button  id="mint-button" className="btn btn-info btn-connect about-us" style={{marginRight:"10px"}} >BUY MORE</button></a> */}
            <a className="btn btn-connect about-us"  rel="noopener">
            <ConnectWallet
             btnTitle="Mint War Bonds"
                theme="dark"
              />
          </a> 
            </div> 

            <div className="col-xl-8 col-lg-9 col-md-10">
            <a href="https://etherscan.io/address/0xcacb0a5bb1f52f00a14bda0dc85de81392b2892b" target="_blank" className="btn btn-icon btn-secondary btn-facebook rounded-circle mx-1 d-lg-inline-flex"><img style={{height:"20px"}} src="etherscan.png"></img></a>
            <a href="https://opensea.io/collection/warbonds" target="_blank" className="btn btn-icon btn-secondary btn-twitter rounded-circle mx-1 d-lg-inline-flex"><img style={{height:"25px"}} src="opensea.png"></img></a>
            </div>      
          </div>

</section>
</main>

  );
}

export default App;